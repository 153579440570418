<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">

      <div class="box">
        <div class="div_bg">
          数据识别
        </div>
        <div class="flex_1 div2">
           <div @click="toIdentification()">数据元与表字段的关联</div>
           <span>分析数据元与表字段的数据关联</span>
        </div>
        <div class="flex_1">
          <div @click="toIdentification()">表字段的关联</div>
          <span>分析多表之间某字段的关联关系</span>
        </div>
        <div class="flex_1">
          <div @click="toIdentification()">表的关联</div>
          <span>分析多表之间的关联关系</span>
        </div>
        <div class="div_bg">
          <div class="div5_1">
            <el-button type="primary">查看更多</el-button>
          </div>
          
          <div>
            <el-button type="primary"  @click="todataAdd()">新建</el-button>
          </div>
        </div>
      </div>
      
      <div class="box m-t-20">
        <div class="div_bg">
          基础模型
        </div>
        <div class="flex_1">
           <div>数据元关联分析</div>
           <el-button  type="text" @click="toModelTraining()">
             <span>模型训练</span>
           </el-button>
        </div>
        <div class="flex_1">
          <div>词法分析</div>
          <el-button  type="text" @click="toModelTraining()">
            <span>模型训练</span>
          </el-button>
        </div>
        <div class="flex_1">
          <div>文本纠错</div>
          <el-button  type="text" @click="toModelTraining()">
            <span>模型训练</span>
          </el-button>
        </div>
        <div class="div_bg">
          <div class="div5_1">
            <el-button type="primary">查看更多</el-button>
          </div>
          
          <div>
            <el-button type="primary" @click="toSupportAlgorithm()">算法模拟</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑

export default {
  name: 'learNing',
  //注册组件
  components: {
    BreadCrumb,
  },
  data() {
    return {
      pageP1: '',
    }
  },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
  },
  methods: {
    //查询
    toIdentification() {
      this.$router.push({
        name: 'dataIdentification'
      })
    },
    todataAdd() {
      this.$router.push({
        name: 'dataAdd'
      })
    },
    toModelTraining(){
      this.$router.push({
        name: 'modelTraining'
      })
    },
    toSupportAlgorithm(){
      this.$router.push({
        name: 'supportAlgorithm'
      })
    }
  },
}
</script>

<style scoped>
  .box{
    display: flex;
    padding: 0 6vw;
  }
  .box>div{
     height: 140px;
         text-align: center;
     border: 1px solid #ccc;
  }
   .box>div.div_bg{
     background: #f1efef;
  }
      @media screen and (min-width:500px) {
         .box{
           padding: 0;
         }
         .box>div.div_bg{
           width: 110px;
         }
      }
      @media screen and (min-width:1200px) {
         .box{
           padding: 0 2vw;
         }
         .box>div.div_bg{
           width: 120px;
         }
      }
      @media screen and (min-width:1500px) {
          .box{
            padding: 0 6vw;
          }
          .box>div.div_bg{
            width: 180px;
          }
      }

  .box .flex_1{
    flex: 1;
  }
  .box .flex_1>div{
    font-size: 18px;
    height: 80px;
    line-height: 80px;
    cursor: pointer;
  }
  .box .flex_1>span{
   color: #928f8f;
       font-size: 16px;
  }
  .box>div:nth-child(1){
    line-height: 140px;
  }
  .box>div:nth-child(5) .div5_1{
    height: 75px;
        line-height: 75px;
  }

  .box .div2 button span>div {
      font-size: 18px;
      height: 80px;
      line-height: 80px;
  }
  .box .div2 button span>span,
  .box .flex_1 button{
          font-size: 16px;
  }
  
</style>
